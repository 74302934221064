#app {
  background-color: #333333;
}

.board {
  background-color: rebeccapurple;
  display: grid;
  grid-template-columns: repeat(5, 60px);
}

.cell {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  margin: 10px;
  border: 2px solid gray;
}
